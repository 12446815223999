import {capitalize, cloneDeep, get, startCase, toLower, toUpper} from 'lodash';
import moment from 'moment';
import React from 'react';
import DatePicker from 'react-datepicker';
import Switch from 'react-switch';
import {
  DateInput,
  DatesRangeInput,
  DateTimeInput,
} from 'semantic-ui-calendar-react';
import {
  Button,
  Dropdown,
  Form,
  Icon,
  Input,
  Menu,
  Tab,
  TextArea,
} from 'semantic-ui-react';
import config from '../../auth_config.json';
import {jobStageMap, PricingItemStatus} from '../../models/Job';
import AxiosHelper from '../../utils/axiosHelper';
import {DataHelper} from '../../utils/dataHelper';
import CostPdfBuilder from '../CostPdfBuilder';
import QuickbooksCustomerSearch from '../Integration/quickbooks/QuickbooksCustomerSearch';
import XeroCustomerSearch from '../Integration/xero/XeroCustomerSearch';
import JobIdSelector from '../JobIdSelector';
import PdfBuilder from '../PdfBuilder';
import DataAvailabilityPicker from './DataAvailabilityPicker';
import {
  CRUDType,
  DataFormProps,
  DataFormType,
  DropdownOption,
  DropdownOptionDescription,
  Field,
  LookupType,
  Section,
} from './DataConst';

export const showDataFormInitialState = {
  status: false,
  formType: CRUDType.CREATE,
};

const buildDropdownDescription = (arr: DropdownOptionDescription[]) => {
  return arr.map((item, index) => {
    if (item.data) {
      return (
        <div key={`dropdown-desc-${item.title}-${index}`}>
          <h3>{item.title}</h3>
          <p>{item.toUpper ? toUpper(item.data) : item.data}</p>
        </div>
      );
    }

    return null;
  });
};

const buildDropdownDescriptionText = (item: DropdownOption) => {
  let str = item.text;

  item.description &&
    item.description.forEach((desc) =>
      desc.data ? (str += ` - ${desc.data}`) : null,
    );

  return str;
};

export const buildSelectOptions = (dataSet: DropdownOption[]) => {
  if (!dataSet) {
    return [];
  }

  return dataSet.map((item: DropdownOption) => {
    if (item.label) {
      return {
        key: item.value,
        value: item.value,
        text: startCase(item.text),
        content: (
          <div className={'DropdownLabel'}>
            <div className={item.label} />
            <p>{item.text}</p>
          </div>
        ),
      };
    }

    return {
      key: item.value,
      value: item.value,
      text: item.description ? buildDropdownDescriptionText(item) : item.text,
      content: item.description && (
        <div className={'DropdownContent'}>
          <div>
            <h3>Name</h3>
            <p>{item.text}</p>
          </div>
          {item.description && buildDropdownDescription(item.description)}
        </div>
      ),
    };
  });
};

const buildSelectContactOptions = (dataSet) => {
  if (!dataSet) {
    return [];
  }

  return dataSet.map((item) => {
    return {
      key: item._id,
      value: item._id,
      text: `${startCase(item.title)} ${
        item.firstName ? startCase(item.firstName) + ' ' : ''
      }${startCase(item.lastName)} - ${startCase(item.address.address1)}, ${
        item.address.address2 ? `${item.address.address2}, ` : ''
      } ${item.address.address3 ? `${item.address.address3}, ` : ''} ${toUpper(
        item.address.postalCode,
      )}`,
      content: (
        <div className={'DropdownContent'}>
          <div>
            <h3>Name</h3>
            <p>
              {startCase(item.title)}{' '}
              {item.firstName ? startCase(item.firstName) + ' ' : ''}
              {startCase(item.lastName)}
            </p>
          </div>
          <div>
            <h3>Address</h3>
            <p>
              {item.address.address1},{' '}
              {item.address.address2 ? item.address.address2 + ', ' : null}
              {item.address.address3} {item.address.postalCode}
            </p>
          </div>
        </div>
      ),
    };
  });
};

const errorIcon = (field: Field) => (field.error ? 'exclamation circle' : null);

const buildTabbedSection = (
  tabs,
  section,
  fields,
  data,
  loggedInCompany?,
  scrollRefs?,
  scrollI?,
) => {
  if (!tabs[section.tab]) {
    tabs[section.tab] = [];
  }

  tabs[section.tab].push({
    menuItem: (
      <Menu.Item key={`menu-item-${section.title}`}>
        <div
          className={`DropdownLabel TabHeader ${
            section.title === data.stage ? 'ActiveStageTab' : ''
          }`}
        >
          <div
            className={DataHelper.statusColourColour(
              data.stages[section.title].status,
            )}
          />
          <p>{`${startCase(
            jobStageMap(section.title, loggedInCompany.stageTitles),
          )}`}</p>
        </div>
      </Menu.Item>
    ),
    render: () => (
      <Tab.Pane key={`tab-pane-${section.tab}-${section.title}`}>
        <div key={`tab-fields-${section.tab}-${section.title}`}>{fields}</div>
      </Tab.Pane>
    ),
  });
};

const buildSections = (
  sections: Section[],
  data,
  handleChange,
  handleSearch,
  loggedInCompany?,
  loggedInUser?,
  scrollRefs?,
) => {
  const tabs: any = {};
  let tabTitle;
  let tabTitleIcon;
  let tabDescription;
  let selectedTab;
  let onTabChange;

  const builtSections: any[] = [];

  let sectionIndex = 0;

  for (let i = 0; i < sections.length; i++) {
    if (sections[i].scrollable && !sections[i].tab) {
      sectionIndex = i;
    }

    if (sections[i].hideSection) {
      continue;
    }

    if (sections[i].tab) {
      selectedTab = sections[i].selectedTab;
      onTabChange = sections[i].onTabChange;
    }

    const fields = buildInputs(
      sections[i].fields,
      data,
      handleChange,
      handleSearch,
      loggedInCompany,
      loggedInUser,
    );

    if (sections[i].tab) {
      tabTitle = sections[i].tabTitle;
      tabTitleIcon = sections[i].titleIcon;
      tabDescription =
        sections[i].description &&
        typeof sections[i].description === 'string' ? (
          <p>{sections[i].description}</p>
        ) : (
          sections[i].description
        );
      buildTabbedSection(
        tabs,
        sections[i],
        fields,
        data,
        loggedInCompany,
        scrollRefs,
        i,
      );
      continue;
    }

    builtSections.push(
      <div
        key={`section-${i}`}
        className={`DataFormSection`}
        ref={(el) => (scrollRefs ? (scrollRefs.current[i] = el) : undefined)}
      >
        {sections[i].title && (
          <div className={'DataFormSectionTitleContainer'}>
            {sections[i].titleIcon}
            <h1>{startCase(sections[i].title)}</h1>
          </div>
        )}
        {sections[i].description && (
          <div className={'DataFormSectionTitleContainer'}>
            {typeof sections[i].description === 'string' ? (
              <p>{sections[i].description}</p>
            ) : (
              sections[i].description
            )}
          </div>
        )}
        <div className={'DataFormSectionFields'}>{fields}</div>
      </div>,
    );
  }

  for (let i = 0; i < Object.keys(tabs).length; i++) {
    const tab = Object.keys(tabs)[i];

    const comp = (
      <>
        {tabTitle && (
          <div className={'DataFormSectionTitleContainer'}>
            {tabTitleIcon ? tabTitleIcon : null}
            <h1>{tabTitle ? startCase(tabTitle) : null}</h1>
          </div>
        )}
        {tabDescription && (
          <div className={'DataFormSectionTitleContainer'}>
            {tabDescription}
          </div>
        )}
        <Tab
          key={`tab-${i}`}
          className={'DataFormSectionTab'}
          panes={tabs[tab]}
          activeIndex={selectedTab ? selectedTab : 0}
          onTabChange={(e, data) => onTabChange(data.activeIndex)}
        />
      </>
    );

    builtSections.push(
      <div
        className={'DataFormTabContainer'}
        key={`tab-container-${i}`}
        ref={(el) =>
          scrollRefs ? (scrollRefs.current[sectionIndex + 1] = el) : undefined
        }
      >
        {comp}
      </div>,
    );
  }

  return builtSections;
};

const completeLookup = (
  type: LookupType,
  dataSet: any[],
  field: string,
  value: string,
) => {
  const item = dataSet.find((data) => data[field] === value);

  if (!item) {
    return null;
  }

  switch (type) {
    case LookupType.ADDRESS:
      return (
        <div className={'LookupAddress'}>
          <h4>Customer Details</h4>
          <div>
            <div className={'LookAddressColumn'}>
              <div>
                <h4>Name</h4>
                <p>
                  {startCase(item.title)}{' '}
                  {item.firstName ? item.firstName + ' ' : ''}
                  {item.lastName}
                </p>
              </div>
            </div>
            {(item.companyName || item.account) && (
              <div className={'LookAddressColumn'}>
                {item.companyName && (
                  <div>
                    <h4>Company</h4>
                    <p>{item.companyName}</p>
                  </div>
                )}
                {item.account && (
                  <div>
                    <h4>Account Number</h4>
                    <p>{item.account}</p>
                  </div>
                )}
              </div>
            )}
            <div className={'LookAddressColumn'}>
              <div>
                <h4>Phone</h4>
                <p>{item.contact.phone}</p>
              </div>
              {item.contact.email && (
                <div>
                  <h4>Email</h4>
                  <p>
                    <a href={`mailto: ${item.contact.email}`}>
                      {item.contact.email}
                    </a>
                  </p>
                </div>
              )}
            </div>
            <div className={'LookAddressColumn'}>
              <h4>Address</h4>
              <p>
                {item.contact.address.address1}
                <br />
                {item.contact.address.address2 && (
                  <>
                    {item.contact.address.address2}
                    <br />
                  </>
                )}
                {item.contact.address.address3}
                <br />
                {item.contact.address.postalCode}
              </p>
            </div>
          </div>
        </div>
      );
    case LookupType.CONTACT:
      return (
        <div className={'LookupAddress'}>
          <div>
            <div className={'LookAddressColumn'}>
              <div>
                <h4>Name</h4>
                <p>
                  {startCase(item.title)}{' '}
                  {item.firstName ? item.firstName + ' ' : ''}
                  {item.lastName}
                </p>
              </div>
            </div>
            <div className={'LookAddressColumn'}>
              <div>
                <h4>Phone</h4>
                <p>{item.phone}</p>
              </div>
              {item.email && (
                <div>
                  <h4>Email</h4>
                  <p>
                    <a href={`mailto: ${item.email}`}>{item.email}</a>
                  </p>
                </div>
              )}
            </div>
            <div className={'LookAddressColumn'}>
              <h4>Address</h4>
              <p>
                {item.address.address1}
                <br />
                {item.address.address2 && (
                  <>
                    {item.address.address2}
                    <br />
                  </>
                )}
                {item.address.address3}
                <br />
                {item.address.postalCode}
              </p>
            </div>
          </div>
        </div>
      );
  }
};

const buildInputs = (
  fields: Field[],
  data,
  handleChange,
  handleSearch,
  loggedInCompany?,
  loggedInUser?,
) => {
  return fields.map((field: Field, index) => {
    if (field.hidden) {
      return null;
    }
    let input;
    switch (field.type) {
      case DataFormType.BUTTON:
        input = (
          <Button
            style={{marginBottom: 10}}
            disabled={data.stage === field.dataSet.stage}
            primary
            onClick={() =>
              handleChange(field.dataSet.stage, field, false, false)
            }
          >
            {data.stage === field.dataSet.stage
              ? field.dataSet.activeButtonText
              : field.dataSet.buttonText}
          </Button>
        );
        break;
      case DataFormType.TEXT:
        input = (
          <Input
            autoComplete={'off'}
            placeholder={field.placeholder || `Enter ${field.title}`}
            value={get(data, field.dataField) || ''}
            onChange={(e) =>
              handleChange(
                field.characterLimit
                  ? e.target.value.slice(0, field.characterLimit)
                  : e.target.value,
                field,
              )
            }
            error={field.error}
            icon={errorIcon(field)}
          />
        );
        break;

      case DataFormType.TEXT_CAPITALISE:
        input = (
          <Input
            autoComplete={'off'}
            placeholder={field.placeholder || `Enter ${field.title}`}
            value={get(data, field.dataField) || ''}
            onChange={(e) =>
              handleChange(
                field.characterLimit
                  ? e.target.value
                      .replace(/\w+/g, capitalize)
                      .slice(0, field.characterLimit)
                  : e.target.value.replace(/\w+/g, capitalize),
                field,
              )
            }
            error={field.error}
            icon={errorIcon(field)}
          />
        );
        break;

      case DataFormType.TEXT_READ_ONLY:
        input = (
          <Input
            autoComplete={'off'}
            placeholder={field.placeholder || `Enter ${field.title}`}
            value={get(data, field.dataField) || ''}
            error={field.error}
            icon={errorIcon(field)}
          />
        );
        break;

      case DataFormType.TEXT_LOWER:
        input = (
          <Input
            autoComplete={'off'}
            placeholder={field.placeholder || `Enter ${field.title}`}
            value={get(data, field.dataField) || ''}
            onChange={(e) =>
              handleChange(
                field.characterLimit
                  ? toLower(e.target.value).slice(0, field.characterLimit)
                  : toLower(e.target.value),
                field,
              )
            }
            error={field.error}
            icon={errorIcon(field)}
          />
        );
        break;

      case DataFormType.TEXT_UPPER:
        input = (
          <Input
            autoComplete={'off'}
            placeholder={field.placeholder || `Enter ${field.title}`}
            value={get(data, field.dataField) || ''}
            onChange={(e) =>
              handleChange(
                field.characterLimit
                  ? toUpper(e.target.value).slice(0, field.characterLimit)
                  : toUpper(e.target.value),
                field,
              )
            }
            error={field.error}
            icon={errorIcon(field)}
          />
        );
        break;

      case DataFormType.TEXT_AREA:
        input = (
          <TextArea
            autoComplete={'off'}
            placeholder={field.placeholder || `Enter ${field.title}`}
            value={get(data, field.dataField)}
            onChange={(e, data) => handleChange(data.value, field)}
            error={field.error}
          />
        );
        break;

      case DataFormType.CURRENCY:
        input = (
          <Input
            autoComplete={'off'}
            type={'number'}
            label={'£'}
            placeholder={field.placeholder || `Enter ${field.title}`}
            value={get(data, field.dataField) || ''}
            onChange={(e) => handleChange(e.target.value, field)}
            error={field.error}
            icon={errorIcon(field)}
          />
        );
        break;

      case DataFormType.PRICE_NET:
        let netTotal = parseFloat(get(data, field.dataField));

        if (data.vat) {
          netTotal = netTotal / 1.2;
        }

        input = (
          <Input
            autoComplete={'off'}
            label={'£'}
            placeholder={field.placeholder || `Enter ${field.title}`}
            value={netTotal.toFixed(2)}
            onChange={(e) => {
              handleChange(e.target.value.replace(/[^\d.-]/g, ''), field);
            }}
            error={field.error}
            icon={errorIcon(field)}
            disabled={true}
          />
        );
        break;

      case DataFormType.PRICE_VAT:
        let vat = 0;
        let net = parseFloat(get(data, field.dataField));

        if (data.vat) {
          vat = net - net / 1.2;
        }

        input = (
          <Input
            autoComplete={'off'}
            type={'number'}
            label={'£'}
            placeholder={field.placeholder || `Enter ${field.title}`}
            value={vat.toFixed(2)}
            onChange={(e) => handleChange(e.target.value, field)}
            error={field.error}
            icon={errorIcon(field)}
            disabled={true}
            style={{opacity: 1}}
          />
        );
        break;

      case DataFormType.PRICE_TOTAL:
        let total = get(data, field.dataField);

        input = (
          <Input
            autoComplete={'off'}
            label={data.vat ? '£ (Inc. VAT)' : '£'}
            placeholder={field.placeholder || `Enter ${field.title}`}
            value={total ? total : ''}
            onChange={(e) => {
              handleChange(e.target.value.replace(/[^\d.-]/g, ''), field);
            }}
            error={field.error}
            icon={errorIcon(field)}
            style={{opacity: 1}}
          />
        );
        break;

      case DataFormType.PRICE_OWED:
        let created = 0;

        if (data.pricing.length) {
          data.pricing.forEach((p) => {
            if (
              [PricingItemStatus.PAID, PricingItemStatus.WRITTEN_OFF].includes(
                p.status,
              )
            ) {
              created = created + parseFloat(p.amount);
            }
          });
        }

        const owed = data.price.value - created;

        input = (
          <Input
            autoComplete={'off'}
            type={'number'}
            label={data.vat ? '£ (Inc. VAT)' : '£'}
            placeholder={field.placeholder || `Enter ${field.title}`}
            value={owed.toFixed(2)}
            disabled={true}
            style={{opacity: 1}}
          />
        );
        break;

      case DataFormType.PRICE_INVOICED:
        let invoiced = 0;

        if (data.pricing.length) {
          data.pricing.forEach((p) => {
            if ([PricingItemStatus.SENT].includes(p.status)) {
              invoiced = invoiced + parseFloat(p.amount);
            }
          });
        }

        input = (
          <Input
            autoComplete={'off'}
            type={'number'}
            label={data.vat ? '£ (Inc. VAT)' : '£'}
            placeholder={field.placeholder || `Enter ${field.title}`}
            value={invoiced.toFixed(2)}
            disabled={true}
            style={{opacity: 1}}
          />
        );
        break;

      case DataFormType.PRICE_PAID:
        let paid = 0;

        if (data.pricing.length) {
          data.pricing.forEach((p) => {
            if ([PricingItemStatus.PAID].includes(p.status)) {
              paid = paid + parseFloat(p.amount);
            }
          });
        }

        input = (
          <Input
            autoComplete={'off'}
            type={'number'}
            label={data.vat ? '£ (Inc. VAT)' : '£'}
            placeholder={field.placeholder || `Enter ${field.title}`}
            value={paid.toFixed(2)}
            disabled={true}
            style={{opacity: 1}}
          />
        );
        break;

      case DataFormType.PRICE_WRITTEN_OFF:
        let off = 0;

        if (data.pricing.length) {
          data.pricing.forEach((p) => {
            if ([PricingItemStatus.WRITTEN_OFF].includes(p.status)) {
              off = off + parseFloat(p.amount);
            }
          });
        }

        input = (
          <Input
            autoComplete={'off'}
            type={'number'}
            label={data.vat ? '£ (Inc. VAT)' : '£'}
            placeholder={field.placeholder || `Enter ${field.title}`}
            value={off.toFixed(2)}
            disabled={true}
            style={{opacity: 1}}
          />
        );
        break;

      case DataFormType.NUMBER:
        input = (
          <Input
            autoComplete={'off'}
            type={'number'}
            placeholder={field.placeholder || `Enter ${field.title}`}
            value={get(data, field.dataField)}
            onChange={(e) => handleChange(e.target.value, field)}
            error={field.error}
            icon={errorIcon(field)}
          />
        );
        break;

      case DataFormType.SELECT:
        let value = get(data, field.dataField);
        input = (
          <Dropdown
            autoComplete={'off'}
            className={field.error ? 'error' : ''}
            clearable
            placeholder={field.placeholder || `Select ${field.title}`}
            fluid
            selection
            options={buildSelectOptions(field.dataSet)}
            value={value || typeof value === 'boolean' ? value : ''}
            onChange={(e, data) => handleChange(data.value, field)}
          />
        );
        break;

      case DataFormType.SEARCH_SELECT_API_CUSTOMER:
        input = (
          <>
            <Dropdown
              autoComplete={'off'}
              className={`positionIcon ${field.error ? 'error' : ''} ${
                field.lookup ? 'DropdownLookup' : ''
              }`}
              icon={field.icon || 'search'}
              clearable
              placeholder={field.placeholder || `Type to search ${field.title}`}
              noResultsMessage={'Type to start searching.'}
              fluid
              search
              onSearchChange={(options, searchObj) =>
                handleSearch(options, searchObj, field.dataType)
              }
              selectOnBlur={false}
              selection
              value={get(data, field.dataField) || ''}
              onChange={(e, data) => {
                if (field.dataField === 'customer') {
                  const contactField = cloneDeep(
                    fields.find((f) => f.dataField === 'contact.installation'),
                  );
                  contactField.dataField = 'contact';

                  handleChange(
                    [data.value, {billing: null, installation: null}],
                    [field, contactField],
                  );
                } else {
                  handleChange(data.value, field);
                }
              }}
              options={buildSelectOptions(field.dataSet)}
            />
            {field.lookup &&
              completeLookup(
                LookupType.ADDRESS,
                field.lookup.dataSet,
                field.lookup.field,
                get(data, field.dataField),
              )}
          </>
        );
        break;

      case DataFormType.SELECT_CONTACT:
        const customerId = get(data, field.dataSubField);
        let contactId = get(data, field.dataField) || '';

        const customer =
          field.lookup &&
          field.lookup.dataSet.find((cus) => cus._id === customerId);

        if (
          customer &&
          !customer.contact.find((con) => con._id === contactId)
        ) {
          contactId = null;

          if (field.dataField === 'contact.billing') {
            const installation = customer.contact.find(
              (con) => con._id === get(data, 'contact.installation'),
            );
            if (installation) {
              contactId = installation._id;
            }
          }
        }

        input = (
          <>
            <Dropdown
              search
              autoComplete={'off'}
              className={`${field.error ? 'error' : ''} ${
                !data.customer ? 'no-customer' : ''
              }`}
              clearable
              placeholder={field.placeholder || `Select ${field.title}`}
              fluid
              selection
              options={
                customer ? buildSelectContactOptions(customer.contact) : []
              }
              value={contactId || ''}
              onChange={(e, dropData) => {
                if (field.dataField === 'contact.installation') {
                  const billing = get(data, 'contact.billing');
                  const contactField = cloneDeep(
                    fields.find((f) => f.dataField === 'contact.installation'),
                  );
                  contactField.dataField = 'contact';
                  if (!billing && contactField) {
                    handleChange(
                      {billing: dropData.value, installation: dropData.value},
                      contactField,
                    );
                  } else {
                    handleChange(dropData.value, field);
                  }
                } else {
                  handleChange(dropData.value, field);
                }
              }}
            />
            {field.lookup &&
              customer &&
              completeLookup(
                LookupType.CONTACT,
                customer.contact,
                field.lookup.field,
                contactId,
              )}
          </>
        );
        break;

      case DataFormType.SELECT_MULTIPLE:
        input = (
          <Dropdown
            autoComplete={'off'}
            className={field.error ? 'error' : ''}
            clearable
            icon={field.icon || 'search'}
            placeholder={field.placeholder || `Select ${field.title}`}
            multiple
            fluid
            selection
            options={buildSelectOptions(field.dataSet)}
            value={get(data, field.dataField) || ''}
            onChange={(e, data) => handleChange(data.value, field)}
          />
        );
        break;

      case DataFormType.SEARCH_SELECT:
        input = (
          <>
            <Dropdown
              autoComplete={'off'}
              className={`${field.error ? 'error' : ''} ${
                field.lookup ? 'DropdownLookup' : ''
              }`}
              clearable
              icon={field.icon || 'search'}
              placeholder={field.placeholder || `Type to search ${field.title}`}
              fluid
              search
              selection
              allowAdditions={field.allowAdditions}
              onAddItem={async (e, data) => {
                const addition = await field.handleAddition(e, data);
                handleChange(addition, field);
              }}
              selectOnBlur={false}
              options={buildSelectOptions(field.dataSet)}
              value={get(data, field.dataField) || ''}
              onChange={(e, data) => handleChange(data.value, field)}
            />
            {field.lookup &&
              completeLookup(
                LookupType.ADDRESS,
                field.lookup.dataSet,
                field.lookup.field,
                get(data, field.dataField),
              )}
          </>
        );
        break;

      case DataFormType.SEARCH_SELECT_API:
        input = (
          <>
            <Dropdown
              autoComplete={'off'}
              className={`positionIcon ${field.error ? 'error' : ''} ${
                field.lookup ? 'DropdownLookup' : ''
              }`}
              icon={field.icon || 'search'}
              clearable
              placeholder={field.placeholder || `Type to search ${field.title}`}
              noResultsMessage={'Type to start searching.'}
              fluid
              search
              onSearchChange={(options, searchObj) =>
                handleSearch(options, searchObj, field.dataType)
              }
              selectOnBlur={false}
              selection
              value={get(data, field.dataField) || ''}
              onChange={(e, data) => handleChange(data.value, field)}
              options={buildSelectOptions(field.dataSet)}
            />
            {field.lookup &&
              completeLookup(
                LookupType.ADDRESS,
                field.lookup.dataSet,
                field.lookup.field,
                get(data, field.dataField),
              )}
          </>
        );
        break;

      case DataFormType.SEARCH_SELECT_API_MULTIPLE:
        input = (
          <>
            <Dropdown
              autoComplete={'off'}
              className={`${field.error ? 'error' : ''} ${
                field.lookup ? 'DropdownLookup' : ''
              }`}
              clearable
              icon={field.icon || 'search'}
              placeholder={field.placeholder || `Search ${field.title}s`}
              noResultsMessage={'Type to start searching.'}
              fluid
              search
              onSearchChange={(options, searchObj) =>
                handleSearch(options, searchObj, field.dataType)
              }
              selectOnBlur={false}
              selection
              value={get(data, field.dataField) || ''}
              onChange={(e, data) => handleChange([data.value], field)}
              options={buildSelectOptions(field.dataSet)}
              multiple
            />
          </>
        );
        break;

      case DataFormType.DATE:
        input = (
          <DateInput
            autoComplete={'off'}
            name={field.title}
            placeholder={field.placeholder || `Select ${field.title}`}
            clearable
            clearIcon={<Icon name="remove" color="red" />}
            value={
              get(data, field.dataField)
                ? moment(get(data, field.dataField)).format('DD-MM-YYYY')
                : ''
            }
            iconPosition="left"
            onChange={(e, data) => handleChange(data.value, field, true)}
            dateFormat="DD-MM-YYYY"
            localization="Europe/London"
            closable={true}
            animation={'fade'}
            duration={100}
          />
        );
        break;

      case DataFormType.DATE_RANGE:
        const rangeToString = (data) => {
          const dates = get(data, field.dataField);
          const split = dates.split(' - ');

          const from =
            split[0] &&
            split[0].length &&
            moment(split[0]).format('DD-MM-YYYY');
          const to =
            split[1] &&
            split[1].length &&
            moment(split[1]).format('DD-MM-YYYY');

          if (!split[0] && !split[1]) {
            return '';
          }

          return from + ' - ' + to;
        };

        input = (
          <DatesRangeInput
            autoComplete={'off'}
            name={field.title}
            placeholder={'From - To'}
            clearable
            clearIcon={<Icon name="remove" color="black" />}
            value={get(data, field.dataField) ? rangeToString(data) : ''}
            iconPosition="right"
            allowSameEndDate={true}
            onChange={(e, data) => handleChange(data.value, field, true)}
            dateFormat="DD/MM/YYYY"
            localization="Europe/London"
            closable={true}
            animation={'fade'}
            duration={100}
          />
        );
        break;

      case DataFormType.DATE_TIME:
        input = (
          <DateTimeInput
            autoComplete={'off'}
            name={field.title}
            placeholder={field.placeholder || 'Select Date & Time'}
            clearable
            clearIcon={<Icon name="remove" color="red" />}
            preserveViewMode={false}
            value={
              get(data, field.dataField)
                ? moment(get(data, field.dataField)).format('DD-MM-YYYY HH:mm')
                : ''
            }
            iconPosition="left"
            onChange={(e, data) => handleChange(data.value, field, false, true)}
            dateFormat="DD-MM-YYYY HH:mm"
            localization="Europe/London"
            closable={true}
            animation={'fade'}
            duration={100}
          />
        );
        break;

      case DataFormType.DATE_SWITCH:
        const switchVal =
          typeof get(data, field.dataField) !== 'undefined'
            ? get(data, field.dataField)
            : field.value;

        let viewingMonth = get(data, field.dataSet.dataField)
          ? moment(get(data, field.dataSet.dataField)).toDate()
          : moment();

        input = (
          <>
            <div className={'DataFormSwitchContainer'}>
              <label>
                {field.content && field.content.label && (
                  <span>{field.content.label}</span>
                )}
                <Switch
                  onChange={(value: boolean) =>
                    handleChange(value, field, false, false)
                  }
                  checked={
                    typeof get(data, field.dataField) !== 'undefined'
                      ? get(data, field.dataField)
                      : field.value || false
                  }
                  offColor={'#dfe4ea'}
                  onColor={'#8dc83c'}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  handleDiameter={20}
                />
              </label>
            </div>

            <DatePicker
              dateFormat={switchVal ? 'dd-MM-yyyy' : 'dd-MM-yyyy hh:mm'}
              selected={
                get(data, field.dataSet.dataField)
                  ? moment(get(data, field.dataSet.dataField)).toDate()
                  : ''
              }
              showTimeSelect={!switchVal}
              timeIntervals={5}
              onCalendarOpen={() =>
                (viewingMonth = get(data, field.dataSet.dataField)
                  ? moment(get(data, field.dataSet.dataField)).toDate()
                  : moment())
              }
              onMonthChange={(month) => (viewingMonth = month)}
              onChange={(date) =>
                handleChange(date, field.dataSet, false, true)
              }
              highlightDates={field.dataSet.disable
                ?.filter((log) =>
                  data.stages?.[
                    field.dataSet.selectedStage
                  ]?.assignedTo?.includes(log.userId),
                )
                .map((log) => log.dates)}
              calendarContainer={(props) => {
                return (
                  <DataAvailabilityPicker
                    className={props.className}
                    availability={field.dataSet.staffAvailability?.filter(
                      (log) => {
                        const selectedDate = viewingMonth || moment();
                        const startDate = moment(log.startDate);
                        const endDate = moment(log.endDate);

                        const dateMatch =
                          (moment(selectedDate).isSame(startDate, 'year') &&
                            moment(selectedDate).isSame(startDate, 'month')) ||
                          (moment(selectedDate).isSame(endDate, 'year') &&
                            moment(selectedDate).isSame(endDate, 'month'));

                        return (
                          dateMatch &&
                          data.stages[
                            field.dataSet.selectedStage
                          ].assignedTo?.includes(log.userId)
                        );
                      },
                    )}
                  >
                    {props.children}
                  </DataAvailabilityPicker>
                );
              }}
            />
          </>
        );
        break;

      case DataFormType.TABLE:
        input = field.content;
        break;

      case DataFormType.BUILD_PDF:
        input = (
          <PdfBuilder
            buttonTitle={field.dataSet.buttonTitle}
            buttonTitleView={field.dataSet.buttonTitleView}
            data={field.dataSet.data}
            stage={field.dataSet.stage}
            loggedInUser={field.dataSet.loggedInUser}
            loggedInCompany={field.dataSet.loggedInCompany}
            action={field.dataSet.action}
            customer={field.dataSet.customer}
            contact={field.dataSet.contact}
            users={field.dataSet.users}
            company={field.dataSet.company}
            onChange={field.dataSet.setter}
          />
        );
        break;

      case DataFormType.BUILD_COST_PDF:
        input = (
          <CostPdfBuilder
            buttonTitle={field.dataSet.buttonTitle}
            buttonTitleView={field.dataSet.buttonTitleView}
            data={field.dataSet.data}
            loggedInUser={field.dataSet.loggedInUser}
            loggedInCompany={field.dataSet.loggedInCompany}
            action={field.dataSet.action}
            customer={field.dataSet.customer}
            contact={field.dataSet.contact}
            users={field.dataSet.users}
            company={field.dataSet.company}
            onChange={field.dataSet.setter}
          />
        );
        break;

      case DataFormType.PASSWORD_RESET:
        const axios = new AxiosHelper('123');
        input = (
          <button
            className={'ui button'}
            onClick={async () => {
              await axios.post(
                `https://${config.domain}/dbconnections/change_password`,
                {
                  client_id: config.clientId,
                  email: data.contact.email,
                  connection: 'Onsite7',
                },
              );
            }}
          >
            Reset Password
          </button>
        );
        break;

      case DataFormType.XERO_CUSTOMER_SEARCH:
        input = (
          <XeroCustomerSearch
            loggedInUser={loggedInUser}
            company={loggedInCompany}
            value={get(data, field.dataField)}
            onChange={(val, f) => handleChange(val, f || field)}
            handleDataAction={field.dataSet.handleDataAction}
          />
        );
        break;

      case DataFormType.QUICKBOOKS_CUSTOMER_SEARCH:
        input = (
          <QuickbooksCustomerSearch
            loggedInUser={loggedInUser}
            company={loggedInCompany}
            value={get(data, field.dataField)}
            onChange={(val, f) => handleChange(val, f || field)}
            handleDataAction={field.dataSet.handleDataAction}
          />
        );
        break;

      case DataFormType.JOB_ID_SELECTOR:
        input = (
          <JobIdSelector
            company={loggedInCompany}
            value={get(data, field.dataField)}
            onChange={(val, f) => handleChange(val, f || field)}
            job={data}
          />
        );
        break;
    }

    return (
      <Form.Field
        key={`${field.title}-${index}`}
        className={`${field.className ? field.className : ''} ${
          field.disabled && data && !data[field.disabled] ? 'disabled' : ''
        }`}
      >
        {field.title && (
          <div className={'FormFieldTitleContainer'}>
            <h3>
              {field.title}{' '}
              {field.characterLimit ? (
                <span>(Max characters: {field.characterLimit})</span>
              ) : null}
            </h3>
            {field.optional &&
            field.optional === 'hidden' ? null : field.optional ? (
              <span style={{fontSize: '13px'}}>*Optional</span>
            ) : (
              <span className={'RedText'}>*Required</span>
            )}
          </div>
        )}
        {field.description && typeof field.description === 'string' ? (
          <p>{field.description}</p>
        ) : (
          field.description
        )}
        {input}
      </Form.Field>
    );
  });
};

const DataForm: React.FunctionComponent<DataFormProps> = (
  props: DataFormProps,
) => {
  // const scrollRefs = useRef([]);

  const sections = buildSections(
    props.sections,
    props.data,
    props.onChange,
    props.onSearch,
    props.loggedInCompany,
    props.loggedInUser,
    props.scrollRefs,
  );

  // const scrollToSection = (ref) => {
  //   scrollRefs.current[ref].scrollIntoView({behavior: 'smooth'});
  // };
  //
  // useEffect(() => {
  //   scrollRefs.current = scrollRefs.current.slice(0, sections.length);
  // }, [sections]);

  return (
    <>
      <Form>{sections}</Form>
    </>
  );
};

export default DataForm;
